import React from 'react';
import PropTypes from 'prop-types';
import { ClickAwayListener, Tooltip } from '@mui/material';

const ClickTooltip = ({ children, title, open, handleClose, ...restProps }) => (
  <ClickAwayListener onClickAway={handleClose}>
    <Tooltip
      onClose={handleClose}
      open={open}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={title}
      {...restProps}
    >
      {children}
    </Tooltip>
  </ClickAwayListener>
);

ClickTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ClickTooltip;
